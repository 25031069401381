import React, { Suspense, useRef, useState } from "react";
import * as THREE from "three";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import { GLTF } from "three-stdlib";
import { useGLTF, meshBounds } from "@react-three/drei";
import styled from "styled-components";

type GLTFResult = GLTF & {
  nodes: {
    ["eye-area"]: THREE.Mesh;
    pupil: THREE.Mesh;
    ["eye-iris"]: THREE.Mesh;
    ["eye-ball"]: THREE.Mesh;
  };
};
{
  /* function Loading() {
  return (
  );
} */
}

function Eyes(props: JSX.IntrinsicElements["group"]) {
  const group = useRef<THREE.Group>(); 
  const { nodes } = useGLTF("/models/eyes.glb") as GLTFResult;
  const { viewport } = useThree();
  const [hovered, setHover] = useState(false);
  const [active, setActive] = useState(false);
  
  {/* useFrame(() => (group.current.rotation.y += 0.001)); */}
  
  useFrame(({ mouse }) => {
    const x = (mouse.x / viewport.width) * 4;
    const y = (mouse.y / viewport.height) * 4;
    group.current?.position.set(x, y, 0);
    group.current?.rotation.set(-y, x, 0);
  });
  
  return (
    <group
      ref={group}
      {...props}
      dispose={null}
      scale={active ? [1.25, 1.25, 1.25] : [1.75, 1.75, 1.75]}
      onClick={() => setActive(!active)}
      onPointerOver={() => setHover(true)}
      onPointerOut={() => setHover(false)}
    >
      <mesh
        geometry={nodes["eye-area"].geometry}
        position={[0, 0, 0.53]}
        castShadow={true}
        raycast={meshBounds}
      >
        <meshPhongMaterial
          attach="material"
          color={hovered ? "#FFCCCC" : "#ffe400"}
          shininess={100}
        />
      </mesh>
      <mesh
        geometry={nodes.pupil.geometry}
        position={[0, 0, -0.06]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.67, 0.67, 0.67]}
        raycast={meshBounds}
      >
        <meshPhongMaterial attach="material" color={0x000000} shininess={100} />
      </mesh>
      <mesh
        geometry={nodes["eye-iris"].geometry}
        position={[0, 0, -0.05]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.67, 0.67, 0.67]}
        raycast={meshBounds}
      >
        <meshPhongMaterial attach="material" color={0x64a52a} shininess={50} />
      </mesh>
      <mesh
        geometry={nodes["eye-ball"].geometry}
        position={[0, 0, -0.05]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.67, 0.67, 0.67]}
        raycast={meshBounds}
      >
        <meshPhongMaterial attach="material" color={0xffffff} shininess={50} />
      </mesh>
    </group>
  );
}

export default function Scene() {
  return (
    <CanvasWrapper>
      <Canvas
        dpr={[1, 2]}
        resize={{ scroll: false }}
        onCreated={({ gl }) => { gl.toneMapping = THREE.NoToneMapping }}
      >
        <ambientLight color={0xf7e7e7} />
        <fog args={[0x03ffe4, 0.5, 2]} />
        <pointLight
          position={[0, 0, 200]}
          color={0xf7e7e7}
          intensity={0.015}
          distance={1000}
          castShadow={true}
        />
        <directionalLight
          position={[1, -1, 1]}
          color={0xf7e7e7}
          intensity={0.15}
        />
        <Suspense fallback={null}>
          <Eyes />
        </Suspense>
      </Canvas>
    </CanvasWrapper>
  );
}
const CanvasWrapper = styled.div`
  width: 100%;
  height: 100%;
`;
