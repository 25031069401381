/** @jsx jsx */
import { jsx, useThemeUI } from "theme-ui";
import { useEffect } from "react";
import { Flex, Box, Text, Heading } from "rebass";
import CompanyTitleListing from "../components/CompanyTitleListing";
import styled from "styled-components";
import Scene from "../components/Scene";
import Arrow from "../icons/Arrow";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import Layout from "../layouts/";
import Header from "../components/Header";
import Footer from "../components/Footer";

export default function Home() {
  let pathname = typeof window !== "undefined" ? window.location.pathname : "";
  const sectionUrl = pathname + "#home-projects";

  const context = useThemeUI();
  const { theme } = context;
  const projectBgColor = theme?.colors?.backgroundElevation as string;
  const projectTextColor = theme?.colors?.text as string;

  useEffect(() => {
    const handleScroll = () => {
      const heroSection = document.getElementById('hero-section');
      if (heroSection) {
        const rect = heroSection.getBoundingClientRect();
        if (rect.bottom > 0) {
          document.body.classList.add('hero-visible');
          document.body.classList.remove('hero-hidden');
        } else {
          document.body.classList.add('hero-hidden');
          document.body.classList.remove('hero-visible');
        }
      } else {
        document.body.classList.add('hero-hidden');
        document.body.classList.remove('hero-visible');
      }
    };
  
    const heroSection = document.getElementById('hero-section');
    if (heroSection) {
      window.addEventListener('scroll', handleScroll);
      handleScroll(); // Initial check
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    } else {
      document.body.classList.add('hero-hidden');
      document.body.classList.remove('hero-visible');
    }
  }, []);

  return (
    <Layout>
      <main>
      <Header
        bgColor={projectBgColor}
        textColor={projectTextColor}
      />

        <Flex
          flexWrap="nowrap"
          justifyContent="center"
          sx={{
            position: "relative",
          }}
        >
          <Box order={[1, 0]} width={[1 / 1]}>
            <Flex flexWrap="wrap" alignItems="flex-end" variant="containerPage">
              <Box order={[2, 0]} width={[1 / 1]}>
                <Heading as="h4" variant="catHeading">
                  I'm Simona
                </Heading>
                <Text as="p" maxWidth="40ch" mb={[4]}>
                  I work in (cross-functional) teams to achieve user experiences
                  that are usable, scalable and lovable. Web and App.{" "}
                  <strong>Across disciplines.</strong>
                </Text>
              </Box>
              <Box order={[1, 0]} mb={[0, 0, 4]} minHeight={["60vh", "auto"]}>
                <Heading
                  as="h1"
                  variant="bigTitle"
                  className="uppercase"
                  sx={{
                    fontSize: [
                      "calc(0.75rem + 16vw)",
                      "calc(0.75rem + 14vw)",
                      "16vw",
                    ],
                  }}
                >
                  UX &amp; <br /> Eye Candy
                </Heading>
                <Text as="p" maxWidth="40ch" mt={[4]}>
                  I design User Experiences, Design Systems and eCommerce
                  Platforms. <strong>Mobile first.</strong>
                </Text>
              </Box>
              <Box
                order={[3, 0]}
                width={[1 / 1]}
                textAlign="center"
                mb={[3, 4]}
              >
                <StyledAnchorLink to={sectionUrl} title="My Contribution">
                  <span>Case Studies</span>
                  <Arrow />
                </StyledAnchorLink>
              </Box>
            </Flex>
          </Box>
          <CanvasWrapper order={[0, 1]} width={[1 / 1]}>
            <Scene />
          </CanvasWrapper>
        </Flex>

        <Box id="home-projects">
          <CompanyTitleListing listProjects="yes" />
          <Flex flexWrap="wrap" alignItems="center" variant="container">
            <Box width={[1 / 1]}>
              <Heading as="h4" variant="catHeading" mb={[2]}>
                Floppy Disks were a thing
              </Heading>
              <Text as="p" maxWidth="40ch">
                Since the beginning of my career, I have had one eye on 
                design and the other on technology. For many years I was the one
                who delivered a website from the layouts to the production code.
              </Text>
            </Box>
          </Flex>
        </Box>
        <Footer />
      </main>
    </Layout>
  );
}
const CanvasWrapper = styled(Box)`
  width: 100vw;
  height: 80svh;
  position: absolute;
  top: 8rem;
  @media only screen and (min-width: 52em) {
    height: 100svh;
    top: 0;
  }
`;

const StyledAnchorLink = styled(AnchorLink)`
  position: relative;
  z-index: 99;
  & > span {
    display: block;
    opacity: 0;
    transition: opacity 0.1s ease-in-out;
    padding: 0.4rem;
  }
  ,
  &:hover span {
    opacity: 1;
  }
  ,
  & > svg,
  & > div > svg {
    width: 3.2rem;
    height: auto;
    transform: rotate(90deg);
  }
`;
